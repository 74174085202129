<template>
  <div>
    <p class="text-black font-medium md:text-[20px] text-[15px]">{{ $t('Customer') }}</p>
    <div class="grid gap-y-4 gap-x-6 mt-7 md:grid-cols-2 grid-cols-1">
      <div>
        <div class="relative h-14">
          <shipblu-select v-if="!selectionInProgress" :options="phones" optionLabel="label" inputId="phone-number" :required="true" :labelPlaceholder="$t('Mobile Number')" labelBackground="white"
            @selectedOptions="selectedPhoneOptions" name="phone number" @input="handlePhoneNumberSearch"/>
          <div v-show="selectionInProgress">
            <shipblu-input labelBackground="white" ref="phoneInput" autocomplete="nofill" id="phone-input" name="phone number" :labelPlaceholder="$t('Phone Number')" :required="true"
              v-model="searchPhoneVal" type="text" class="w-full" v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }">
              <shipblu-icon v-if="selectionInProgress" iconLibrary="feather" iconName="XIcon" iconClass="w-4 cursor-pointer mr-1 text-primary" @click="convertTextToSelect()"/>
              <span class="text-silver" v-if="selectionInProgress && !extraNumber">|</span>
              <shipblu-icon v-if="!extraNumber" iconLibrary="feather" iconName="PlusCircleIcon" iconClass="ml-1 w-4 cursor-pointer text-primary" @click="extraNumber = !extraNumber"/>
            </shipblu-input>
          </div>
          <span class="absolute text-primary right-2 bg-white px-2 flex items-center" style="top: 12px;">
            <shipblu-icon v-if="!extraNumber && !selectionInProgress" iconLibrary="feather" iconName="PlusCircleIcon" iconClass="ml-1 w-4 cursor-pointer" @click="extraNumber = !extraNumber"/>
          </span>
        </div>
        <p class="text-danger text-sm" v-show="errors.has('phone number')">{{ phoneErrorMessage }}</p>
      </div>
      <div>
        <shipblu-input labelBackground="white" autocomplete="nofill" id="name-input" v-validate="'required|max:20'" name="full name" :labelPlaceholder="$t('Full Name')" :required="true" v-model="customerData.full_name" type="text" class="w-full" />
        <p class="text-danger text-sm" v-show="errors.has('full name')">{{ $t('Full name is required') }}</p>
      </div>
      <template v-if="extraNumber">
        <div>
          <shipblu-input labelBackground="white" autocomplete="nofill" id="extra-mobile-number-input" name="extra mobile number" :labelPlaceholder="$t('Extra Mobile Number (Optional)')"  v-model="customerData.secondary_phone" type="text" class="w-full" 
          v-validate="{ regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" />
        </div>
      </template>
      <div class="autoZoning md:col-span-2 flex custom-switch">
        <vs-switch @change="completeAddress = !autoZoning" class="mr-3" v-model="autoZoning" />
        <span class="font-medium text-black">{{$t('Auto-zoning')}}</span>
      </div>
      <template>
        <div>
          <shipblu-input labelBackground="white" @blur="fillAddress()" @keyup.enter.native="autoZoning ? fillAddress() : ''" autocomplete="nofill" v-validate="'required|min:10'" name="full address" :labelPlaceholder="$t('Full Address')" :required="true" v-model="customerData.street_building" type="text" class="w-full" />
          <p class="text-danger text-sm" v-show="errors.has('full address')">{{ $t(errors.first('full address')) }}</p>
        </div>
        <div v-if="!autoZoning">
          <shipblu-select :defaultValue="customerData.zone !== '' ? customerData : null" :key="updatedZonesKey" v-validate="customerData.zone ? '' : 'required'" :localize="true" :options="zones" optionLabel="name" inputId="input-zone" :required="true" :labelPlaceholder="$t('Zone')" labelBackground="white" name="zone" type="high_level" @selectedOptions="customerData.zone = $event[0]"/>
          <p class="text-danger text-sm" v-show="errors.has('zone')">{{ $t('Zone field is required') }}</p>
        </div>
        <div v-show="autoZoning" class="relative cursor-not-allowed">
          <shipblu-input labelBackground="white" autocomplete="nofill" :labelPlaceholder="$t('Zone')" :required="true" v-model="searchQuery" type="text" class="w-full pointer-events-none" :tabindex="-1" />
          <div v-if="loadAddress" class="absolute top-4 left-4 flex items-center gap-[8px] bg-white z-[1]">
            <div class="border-r-white border border-solid border-primary w-[14px] h-[14px] rounded-full flex-shrink-0 animate-spin"></div>
            <p class="text-industrial-revolution">{{ $t('Auto zoning is working..') }}</p>
          </div>
        </div>
      </template>
      <div v-if="activeTab === 'cash-collections'">
        <shipblu-input labelBackground="white" autocomplete="nofill" v-validate="'required'" name="cash amount" :labelPlaceholder="$t('Cash Amount')" :required="true" v-model="customerData.cash_collections" type="text" class="w-full" @blur="validateCashCollections"/>
        <p class="text-danger text-sm" v-show="errors.has('cash amount')">{{ $t('Cash Amount is required') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import common from '../../../assets/utils/common'
import { sendRequest }  from '../../../http/axios/requestHelper'
import staticZones from '../../../assets/utils/geo.json'
import i18nData from '../../../i18n/i18nData'

export default {
  props: ['activeTab', 'changebleTabValue', 'restData', 'customerData'],
  data () {
    return {
      selectionInProgress: false,
      autoZoning: false,
      extraNumber: false,
      zones: staticZones,
      searchQuery: '',
      phones: [],
      searchPhoneVal: '',
      completeAddress: false,
      loadAddress: false,
      updatedZonesKey : 0
    }
  },
  watch: {
    autoZoning () {
      this.searchQuery = ''
    },
    restData (val) {
      if (val) {
        this.resetForm()
        this.$emit('restData', false)
      }
    }
  },
  computed: {
    phoneErrorMessage () {
      const value = this.searchPhoneVal || (this.customerData.phone && typeof this.customerData.phone === 'string' ? this.customerData.phone : '')
      if (!value) return this.$t('Mobile number is required')
      if (value.length !== 11) return this.$t('Phone number should contain 11 numbers')
      if (!value.startsWith('01')) return this.$t('Phone number should start with 01')
      return this.errors.first('phone number')
    }
  },
  methods: {
    selectedPhoneOptions (value) {
      const zoneId = value[0].customer.address.zone.id
      this.loadZones(zoneId)
      this.customerData.full_name = value[0] ? value[0].customer.full_name : ''
      this.searchPhoneVal = value[0] ? value[0].customer.phone : ''
      this.customerData.phone = value[0] ? value[0].customer.phone : ''
      this.customerData.secondary_phone = value[0] && value[0].customer.secondary_phone !== null ? value[0].customer.secondary_phone : null
      this.customerData.street_building = value[0] && value[0].customer.address.line_1 !== null ? `${value[0].customer.address.line_1} ${value[0].customer.address.line_2}` : ''
      this.updatedZonesKey++
      this.selectionInProgress = true
    },
    resetForm () {
      this.searchQuery = ''
      this.customerData = {
        phone: '',
        full_name: '',
        secondary_phone: '',
        street_building: '',
        zone: '',
        governorate: '',
        city: ''
      }
      this.selectionInProgress = false
      if (this.changebleTabValue.newTab) {
        this.$router.push({
          query: {
            tab: this.changebleTabValue.newTab
          }
        }).catch(() => {})
      }
      this.convertTextToSelect()
      this.$emit('activeTab', this.activeTab)
      this.$validator.reset()
    },
    fillAddress () {
      if (this.customerData.street_building.length > 10) {
        this.loadAddress = true
        this.searchQuery = ''
        const orderAddress = {
          full_address: this.customerData.street_building ? this.customerData.street_building : ''
        }
        sendRequest(true, false, this, 'api/v2/gpt/autozoner/', 'post', orderAddress, true, 
          (response) => {
            this.loadAddress = false
            this.completeAddress = true
            this.customerData = {
              ...this.customerData,
              street_building: response.data.line_1,
              governorate: response.data.zone.city.governorate,
              zone: response.data.zone
            }
            this.loadZones(this.customerData.zone.id)
          }
        )
      }
    },
    handlePhoneNumberSearch (search) {
      this.searchPhoneVal = search ? search : ''
      if (this.searchPhoneVal) {
        return common.manageSearch(search, this.loadPhoneNumber, 1000, 4)
      }
    },
    loadPhoneNumber () {
      sendRequest(true, false, this, `api/v1/delivery-orders/?customer_phone=${this.searchPhoneVal}`, 'get', null, true,
        (response) => {
          this.phones = response.data.results
          this.phones.forEach(item => {
            item.label = `${item.customer.phone} ${item.customer.full_name}`
          })
          if (response.data.results.length === 0) {
            this.customerData.phone = this.searchPhoneVal
            this.selectionInProgress = true
          }
          this.$validator.validate('phone number')
        },
      )
    },
    convertTextToSelect () {
      this.customerData = {
        phone: '',
        full_name: '',
        secondary_phone: '',
        street_building: '',
        zone: '',
        governorate: '',
        city: ''
      }
      this.searchPhoneVal = ''
      this.selectionInProgress = false
      this.searchQuery = ''
      this.updatedZonesKey++
      this.$validator.reset()
    },
    validateCashCollections () {
      const value = parseFloat(this.customerData.cash_collections)
      if (isNaN(value)) {
        this.customerData.cash_collections = (0).toFixed(2)
        
      } else {
        this.customerData.cash_collections = value.toFixed(2)
      }
    },
    loadZones (zoneId) {
      if (!Array.isArray(this.zones)) {
        return
      }
      const matchingRegion = this.zones.find((region) => Array.isArray(region.cities) && region.cities.some((city) => Array.isArray(city.zones) && city.zones.some((zone) => zone.id === zoneId)))
      if (matchingRegion) {
        const matchingCity = matchingRegion.cities.find((city) => Array.isArray(city.zones) && city.zones.some((zone) => zone.id === zoneId))
        if (matchingCity) {
          const matchingZone = matchingCity.zones.find((zone) => zone.id === zoneId)
          if (matchingZone) {
            this.customerData.zone = matchingZone && matchingZone
            this.customerData.governorate = matchingRegion && matchingRegion
            this.customerData.city = matchingCity && matchingCity
            this.searchQuery = `${i18nData[this.$i18n.locale][matchingRegion.name]} - ${i18nData[this.$i18n.locale][matchingCity.name]} - ${i18nData[this.$i18n.locale][matchingZone.name]}`
          }
        }
      }
    },
    validatePhoneNumber (value) {
      if (!value) return {
        valid: false,
        msg: this.$t('Mobile number is required')
      }
      if (value.length !== 11) {
        return {
          valid: false,
          msg: this.$t('Phone number should contain 11 numbers')
        }
      }
      if (!value.startsWith('01')) {
        return {
          valid: false,
          msg: this.$t('Phone number should start with 01')
        }
      }
      return { valid: true }
    }
  },
  mounted () {
    this.$emit('customerData', this.customerData)
  }
} 
</script>

<style lang="scss">
.vs__selected-options {
  overflow: hidden;
  white-space: nowrap;
}
.autoZoning .vs-switch--circle{
  background: #D1D1D1 !important;
  margin-top: -1px;
}
.custom-switch .vs-switch {
  border: 1px solid #BFBFBF;
  background-color: transparent;
}
.vs-switch-primary.vs-switch-active{
  background-color: rgba(var(--vs-primary),1);
  border: 1px solid rgba(var(--vs-primary),1);
}
.vs-switch-active .vs-switch--circle{
  background: #fff !important;
}
.vs__search, .vs__search:focus{
  padding: 0px 12px;
}
.dropdown-selector-class {
  max-height: 130px;
}
.vs__dropdown-menu {
  margin-top: 8px;
  border: 1px solid #b8c2cc !important;
  box-shadow: none;
  background-color: #f8f8f8;
}
#vs1__combobox {
  padding: 0 5px;
}
.vs__selected{
  padding: 1px 14px;
  color: #232323 !important;
}
.vs--single.vs--open .vs__selected, .vs--single.vs--loading .vs__selected {
  padding: 2px 13px;
  opacity: 1 !important;
}
</style>